import {
  ColoringFunctions,
  ColoringModeDatasetData,
  DatasetData,
  GenericRuntimeDecisionData,
  MapLayerTags,
} from '@/utils/layers/layout-config'
import {LayerRole} from '@/auth/roles'
import {ColorRGBA, GeometryTypes, GeometryZoomLevels, hexToRgb} from '@/utils/layers/layout-vector-config'
import {DEFAULT_DATASET_COLOR} from '@/models/admin/GenericDataset.model'
import {Feature, GeoJsonProperties, Geometry} from 'geojson'

export const taxCreditDataLayer = 'US 30c tax credit data'
export const usDnoCoverageData = 'US DNO coverage data'
export const alternativeFuelCorridors = 'Alternative Fuel Corridors'
export const justice40tracts = 'Justice40 tracts'
export const usTrafficData = 'US traffic data' 
export const usLandParcels = 'Land parcels'
export const usVehicleRegistrations = 'Vehicle registrations'
export const usUtilityCoverageData = 'US Utility coverage data'

interface PartialLayer {
  name: string
  zoom: number
  role?: LayerRole,
  tooltips: {text: string, property: string}[],
  content_type?: GeometryTypes,
}

const layers: Record<string, PartialLayer[]> = {
  'Traffic': [
    { name: 'NY State Department Of Transportation: Vehicle Miles Travelled', zoom: GeometryZoomLevels.normalGeometry, role: LayerRole.trafficFromNYStateDepartment, tooltips: [] },
    { name: 'NY State Department Of Transportation: Average Daily Traffic Count', zoom:  GeometryZoomLevels.normalGeometry, role: LayerRole.trafficFromNYStateDepartment, tooltips: [] },
    { name: 'NY State Department Of Transportation: Vehicle Miles Travelled, buffered 1-lane', zoom:  GeometryZoomLevels.normalGeometry, role: LayerRole.trafficFromNYStateDepartment, tooltips: [] },
    { name: 'NY State Department Of Transportation: Average Daily Traffic Count, buffered 1-lane', zoom:  GeometryZoomLevels.normalGeometry, role: LayerRole.trafficFromNYStateDepartment, tooltips: [] },
    { name: usTrafficData, zoom: 10, role: LayerRole.trafficDensity, tooltips: [] },
    {
      name: 'TRAFFIC_DENSITY',
      zoom: 15,
      tooltips: [],
      role: LayerRole.trafficDensityAggregatedUs,
    },
  ],
  'Demographic': [
    { name: 'Housing units density per sq. km, US Census', zoom:  GeometryZoomLevels.normalGeometry, tooltips: [{ text: 'Housing units density', property: 'value' }]},
    { name: 'Household income, US Census', zoom:  GeometryZoomLevels.normalGeometry, tooltips: [{ text: 'Household income', property: 'value' }] },
    { name: 'Number of vehicles per household, US Census', zoom:  GeometryZoomLevels.normalGeometry, tooltips: [{ text: 'Number of vehicles', property: 'value' }] },
    { name: 'Percentage of using car, van or truck as transportation to work, US Census', zoom:  GeometryZoomLevels.normalGeometry, tooltips: [{ text: 'Percentage of using car, van or truck as transportation to work', property: 'value' }] },
    { name: 'Multi unit housing percentage, US Census', zoom:  GeometryZoomLevels.normalGeometry, tooltips: [{ text: 'Multi unit housing percentage', property: 'value' }] },
    { name: 'Number of vehicles available, US Census', zoom:  GeometryZoomLevels.normalGeometry, tooltips: [{ text: 'Number of vehicles available', property: 'value' }] },
    { name: 'Median travel time to work, US Census', zoom:  GeometryZoomLevels.normalGeometry, tooltips: [{ text: 'Median travel time', property: 'name' }] },
    { name: 'Work outside of area of residency, percentage, US Census', zoom:  GeometryZoomLevels.normalGeometry, tooltips: [{ text: 'Work outside of area of residency', property: 'value' }] },
    { name:  'Median time of departure to work, for workers departing [5:00 - 10:00], US Census', zoom:  GeometryZoomLevels.normalGeometry, tooltips: [{ text: 'Median time of departure to work', property: 'name' }] },
    { name: 'Percentage below poverty level, US Census', zoom:  GeometryZoomLevels.normalGeometry, tooltips: [{ text: 'Percentage below poverty level', property: 'value' }] },
    { name: 'Education: Percentage with bachelor\'s degree or above, US Census', zoom:  GeometryZoomLevels.normalGeometry, tooltips: [{ text: 'Percentage with bachelor\'s degree', property: 'value' }] },
    { name: 'Population density per square km, US Census', zoom:  GeometryZoomLevels.normalGeometry, tooltips: [{ text: 'Population density', property: 'value' }] },
    { name: 'Housing: percentage of occupied housing units, US Census', zoom:  GeometryZoomLevels.normalGeometry, tooltips: [{ text: 'Housing percentage', property: 'value' }] },
  ],
  'Other': [
    { name: usVehicleRegistrations, zoom:  GeometryZoomLevels.normalGeometry, tooltips: [{text: 'Number of registered EVs', property: 'electric_vehicles'}] },
    { name: 'Alternative Fuel Corridors 1-mile catchment area, rounds 1-6', zoom:  GeometryZoomLevels.normalGeometry, tooltips: [{ text: 'Utility Name:', property: 'name' }] },
    { name: taxCreditDataLayer, zoom: 10, tooltips: [] },
    { name: usDnoCoverageData, zoom: GeometryZoomLevels.sparseGeometry, tooltips: [{ text: 'Utility name:', property: 'name' }]},
    { name: justice40tracts, zoom: GeometryZoomLevels.sparseGeometry, tooltips: [] },
    { name: usLandParcels, zoom: GeometryZoomLevels.veryDenseGeometry, tooltips: [] },
  ],
}


export const layersUS: DatasetData[] = Object.entries(layers).flatMap(([group, items]) => {
  // Change styles
  if (group === 'Traffic') {
    return items.map(({ name, zoom, role }) => {
      if (name === 'TRAFFIC_DENSITY') {
        return new GenericRuntimeDecisionData({
          name: 'Traffic density',
          group,
          role,
          minZoom: zoom,
          dataset: name,
          content_type: GeometryTypes.LineString,
          coloring_mode: ColoringModeDatasetData.colorFunction,
          tag: MapLayerTags.uiLayer,
          color: new ColoringFunctions(
            [0,0,0,0],
            (feature: Feature<Geometry, GeoJsonProperties>): [number, number, number, number] => {
              const propertyValue = feature.properties?.w || 0
              if (propertyValue <= 1000) {
                return [244, 233, 154, 255]
              } else if (propertyValue > 1000 && propertyValue <= 5000) {
                return [215, 174, 101, 255]
              } else if (propertyValue > 5000 && propertyValue <= 10000) {
                return [194, 122, 108, 255]
              } else if (propertyValue > 10000 && propertyValue <= 30000) {
                return [228, 149, 120, 255]
              } else if (propertyValue > 30000 && propertyValue <= 120000) {
                return [156, 108, 221, 255]
              } else {
                return [32, 99, 242, 255]
              }
            },
          ),
          mapKeyOverride: [
            { color: ['#F4E99A'], titleLeft: '0', titleRight: '1,000' },
            { color: ['#D7AE65'], titleLeft: '1,000', titleRight: '5,000' },
            { color: ['#C27A6C'], titleLeft: '5,000', titleRight: '10,000' },
            { color: ['#E49578'], titleLeft: '10,000', titleRight: '30,000' },
            { color: ['#9C6CDD'], titleLeft: '30,000', titleRight: '120,000' },
            { color: ['#2063F2'], titleLeft: '120,000', titleRight: '454,000' },
          ],
          tooltipProperties: [
            {
              property: 'w',
              text: 'Whole week daily average',
              rounded: 0,
            },
            {
              property: 'we',
              text: 'Weekend daily average',
              rounded: 0,
            },
            {
              property: 'wd',
              text: 'Weekday daily average',
              rounded: 0,
            },
            {
              property: 'frc',
              text: 'Functional road class',
              rounded: 0,
            },
            {
              property: 'fow',
              text: 'Form of way',
              rounded: 0,
            },
            {
              property: 'x_day',
              text: 'Whole week daily average (unadjusted)',
              rounded: 0,
            },
          ],
        })
      }
      if (name === usTrafficData) {
        return new GenericRuntimeDecisionData({
          name: usTrafficData,
          group,
          role,
          minZoom: zoom,
          dataset: name,
          content_type: GeometryTypes.LineString,
          coloring_mode: ColoringModeDatasetData.colorFunction,
          tag: MapLayerTags.uiLayer,
          color: new ColoringFunctions(
            [0,0,0,0],
            (feature: Feature<Geometry, GeoJsonProperties>): [number, number, number, number] => {
              const propertyValue = feature.properties?.value || 0
              if (propertyValue <= 1000) {
                return [244, 233, 154, 255]
              } else if (propertyValue > 1000 && propertyValue <= 2300) {
                return [215, 174, 101, 255]
              } else if (propertyValue > 2300 && propertyValue <= 4600) {
                return [194, 122, 108, 255]
              } else if (propertyValue > 4600 && propertyValue <= 9000) {
                return [228, 149, 120, 255]
              } else if (propertyValue > 9000 && propertyValue <= 19500) {
                return [156, 108, 221, 255]
              } else {
                return [32, 99, 242, 255]
              }
            },
          ),
          mapKeyOverride: [
            { color: ['#F4E99A'], titleLeft: '0', titleRight: '1,000' },
            { color: ['#D7AE65'], titleLeft: '1,000', titleRight: '2,300' },
            { color: ['#C27A6C'], titleLeft: '2,300', titleRight: '4,600' },
            { color: ['#E49578'], titleLeft: '4,600', titleRight: '9,000' },
            { color: ['#9C6CDD'], titleLeft: '9,000', titleRight: '19,500' },
            { color: ['#2063F2'], titleLeft: '19,500', titleRight: '461,000' },
          ],
          tooltipProperties: [
            {
              property: 'value',
              text: 'Average Annual Daily Traffic',
              rounded: 0,
            },
          ],
        })
      }
      return new GenericRuntimeDecisionData({
        name,
        group,
        role,
        minZoom: zoom,
        dataset: name,
        content_type: GeometryTypes.LineString,
        coloring_mode: ColoringModeDatasetData.gradientAlgorithm,
        tag: MapLayerTags.uiLayer,
        tooltipProperties: [
          { text: 'Average Annual Daily Traffic:', property: 'value' },
        ],
      })
    })
  }
  return items.map(({ name, zoom, role, tooltips, content_type }) => {
    if (name === taxCreditDataLayer) {
      const yellow = hexToRgb('#EDFF1980')
      const green = hexToRgb('#4CC54AB3')
      const darkGreen = hexToRgb('#1F941D80')
      const coloringFn = (f: Feature): ColorRGBA => {
        switch (f.properties?.tract) {
          case 1:
            return yellow as ColorRGBA
          case 2:
          case 3:
            return green as ColorRGBA
          case 4:
          case 5:
          case 7:
            return darkGreen as ColorRGBA
          default:
            return [0, 0, 0, 10]
        }
      }

      const coloringFunction = new ColoringFunctions(
        coloringFn,
        coloringFn,
      )

      const valueStyle: Partial<CSSStyleDeclaration> = {
        color: 'var(--Gray-100, #212529)',
        fontFamily: 'Roboto',
        fontSize: '12px',
        fontStyle: 'normal',
        fontWeight: '500',
        lineHeight: 'normal',
      }
      const labelStyle: Partial<CSSStyleDeclaration> = {
        display: 'none',
      }

      return new GenericRuntimeDecisionData({
        name,
        group,
        role,
        tag: MapLayerTags.uiLayer,
        dataset: name,
        minZoom: GeometryZoomLevels.normalGeometry,
        content_type: content_type ?? GeometryTypes.Polygon,
        coloring_mode: ColoringModeDatasetData.colorFunction,
        color: coloringFunction,
        mapKeyOverride: [
          { color: ['#EDFF1980'], titleRight: '2024 (2011-2015 NMTC tracts)' },
          { color: ['#4CC54AB3'], titleRight: '2029 (2016-2020 NMTC tracts)' },
          { color: ['#1F941D80'], titleRight: '2030 (2020 Non-Urban tracts)' },
        ],
        tooltipProperties: [
          {
            text: 'Tract ID:',
            property: 'tract',
            valueStyle,
            labelStyle,
            valueTransform: (value: any) => {
              switch (value) {
                case 1:
                  return 'Eligible tract through 2024 (2011-2015 NMTC tracts)'
                case 2:
                case 3:
                  return 'Eligible tract through 2029 (2016-2020 NMTC tracts)'
                case 4:
                case 5:
                case 6:
                case 7:
                  return 'Eligible tract through 2030 (2020 Non-Urban tracts)'
                default:
                  return value
              }
            },
          },
        ],
      })
    }

    if (name === usDnoCoverageData) {
      return new GenericRuntimeDecisionData({
        name: 'US Utility coverage data',
        group,
        role,
        minZoom: zoom,
        content_type: content_type ?? GeometryTypes.Polygon,
        coloring_mode: ColoringModeDatasetData.oneColorPolygon,
        tag: MapLayerTags.uiLayer,
        color: DEFAULT_DATASET_COLOR,
        dataset: name,
        tooltipProperties: tooltips,
        canOverlap: true,
      })
    }

    if (name.includes(alternativeFuelCorridors)) {
      return new GenericRuntimeDecisionData({
        name,
        group,
        role,
        minZoom: zoom,
        dataset: name,
        content_type: content_type ??GeometryTypes.Polygon,
        coloring_mode: ColoringModeDatasetData.oneColorPolygon,
        color: DEFAULT_DATASET_COLOR,
        tag: MapLayerTags.uiLayer,
        tooltipProperties: tooltips,
      })
    }

    if (name === justice40tracts) {
      return new GenericRuntimeDecisionData({
        minZoom: zoom,
        name: 'Justice40 tracts',
        group: 'Other',
        description: 'Justice40 Disadvantaged Tracts by State',
        dataset: 'JUSTICE_40_TRACTS',
        content_type: GeometryTypes.Polygon,
        tag: MapLayerTags.uiLayer,
        coloring_mode: ColoringModeDatasetData.oneColorPolygon,
        color: DEFAULT_DATASET_COLOR,
        tooltipProperties: [
          {
            text: 'State',
            property: 'state',
            rounded: 0,
          },
          {
            text: 'County',
            property: 'county',
            rounded: 0,
          },
          {
            text: 'Categorized as disadvantaged',
            property: 'disadvantaged',
            rounded: 0,
          },
        ],
      })
    }

    if (name === usLandParcels) {
      return new GenericRuntimeDecisionData({
        minZoom: zoom,
        name: name,
        group: group,
        description: 'Land parcel polygons with owner name and address',
        dataset: 'LAND_PARCELS',
        content_type: GeometryTypes.Polygon,
        tag: MapLayerTags.uiLayer,
        coloring_mode: ColoringModeDatasetData.oneColorPolygon,
        color: '#EDAD06',
        tooltipProperties: [
          {
            text: 'Primary owner name',
            property: 'primary_owner',
          },
          {
            text: 'Primary owner address',
            property: 'primary_owner_adr',
          },
        ],
      })
    }

    if (name === usVehicleRegistrations) {
      return new GenericRuntimeDecisionData({
        minZoom: GeometryZoomLevels.normalGeometry,
        name: 'EV registrations',
        group: 'Other',
        description: 'Number of registered EVs',
        dataset: 'US_VEHICLE_REGISTRATION',
        content_type: content_type ?? GeometryTypes.Polygon,
        coloring_mode: ColoringModeDatasetData.gradientAlgorithm,
        tag: MapLayerTags.uiLayer,
        tooltipProperties: [
          {
            text: 'Number of registered EVs',
            property: 'electric_vehicles',
          },
        ],
      })
    }

    return new GenericRuntimeDecisionData({
      name,
      group,
      role,
      minZoom: zoom,
      dataset: name,
      content_type: content_type ?? GeometryTypes.Polygon,
      coloring_mode: ColoringModeDatasetData.gradientAlgorithm,
      tag: MapLayerTags.uiLayer,
      tooltipProperties: tooltips,
    })
  })
})
