import {Feature, Geometry, GeoJsonProperties} from 'geojson'
import {DatasetColorDetail} from '@/libs/loaders/dodona-backend/types'
import { DatasetColoringAlgorithm } from '@/utils/layers/layout-config'

export type ColorRGB = [number, number, number]
export type ColorRGBA = [number, number, number, number]

export enum GeometryZoomLevels {
  sparseGeometry = 8,
  normalGeometry = 10, // think household income
  denseGeometry = 12,
  veryDenseGeometry = 14 // think land analysis
}

export enum GeometryTypes {
  Point = 'Point',
  MultiPoint = 'MultiPoint',
  LineString = 'LineString',
  MultiLineString = 'MultiLineString',
  Polygon = 'Polygon',
  MultiPolygon = 'MultiPolygon'
}

export function getPresentableGeometryType(type: GeometryTypes | undefined) {
  switch (type) {
    case GeometryTypes.MultiPoint:
    case GeometryTypes.Point:
      return 'Point'
    case GeometryTypes.MultiLineString:
    case GeometryTypes.LineString:
      return 'Line'
    case GeometryTypes.Polygon:
    case GeometryTypes.MultiPolygon:
      return 'Polygon'
    default:
      return ''
  }
}

export function hexToRgb(hex: string): ColorRGB | ColorRGBA {
  // Remove the "#" if present
  let cleanHex = hex.replace('#', '')

  if (!(/[0-9a-f]+/i.test(cleanHex) && [3, 4, 6, 8].includes(cleanHex.length))) {
    throw new Error(`${cleanHex} is not a valid hex color.`)
  }

  if (cleanHex.length === 3 || cleanHex.length === 4) {
    // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
    cleanHex = cleanHex
      .split('')
      .map((c) => c + c)
      .join('')
  }

  // Extract and convert each color component
  const r = parseInt(cleanHex.substring(0, 2), 16)
  const g = parseInt(cleanHex.substring(2, 4), 16)
  const b = parseInt(cleanHex.substring(4, 6), 16)

  if (cleanHex.length === 8) {
    const a = parseInt(cleanHex.substring(6, 8), 16)
    return [r, g, b, a]
  }

  return [r, g, b]
}

export const STRING_ENUM_COLORS: string[] = [
  '#FF450080', // Orange Red
  '#00CED180', // Dark Turquoise
  '#FFD70080', // Gold
  '#1E90FF80', // Dodger Blue
  '#32CD3280', // Lime Green
  '#69696980', // Dim Gray
  '#FF634780', // Tomato
  '#2F4F4F80', // Dark Slate Gray
  '#4682B480', // Steel Blue
  '#80800080', // Olive
  '#FF149380', // Deep Pink
  '#FFA50080', // Orange
  '#8A2BE280', // Blue Violet
  '#7FFF0080', // Chartreuse
  '#DC143C80', // Crimson
  '#FF69B480', // Hot Pink
  '#00FF7F80', // Spring Green
  '#6A5ACD80', // Slate Blue
]

const STRING_ENUM_COLORS_RGBA: ColorRGBA[] = STRING_ENUM_COLORS.map(c => hexToRgb(c) as ColorRGBA)


export const GENERIC_LAYER_COLORS = [
  '#440154A0',
  '#46015EA0',
  '#48116BA0',
  '#432A72A0',
  '#3E3C79A0',
  '#384E80A0',
  '#326087A0',
  '#2D708EA0',
  '#2A7E8EA0',
  '#268C8DA0',
  '#229A8CA0',
  '#26A784A0',
  '#3DBA74A0',
  '#5FD260A0',
  '#85DE4BA0',
  '#B3E632A0',
  '#D6EC20A0',
  '#FDE725A0',
]

const colors = GENERIC_LAYER_COLORS.map(hexToRgb)

export const getGenericDatasetColor = (colorDetail: DatasetColorDetail, opacity: number, nullColor: [number, number, number, number] = [0, 0, 0, 0]) =>(f: Feature<Geometry, GeoJsonProperties>): ColorRGBA => {
  const prop = colorDetail.coloring_property

  const value = f.properties?.[prop]

  if (value === undefined) {
    return nullColor
  }

  if (colorDetail.coloring_algorithm === DatasetColoringAlgorithm.byString) {
    const presentation = colorDetail.brackets as string[]

    const index = presentation.findIndex(v => v === value)
    if (index >= 0 && index < STRING_ENUM_COLORS.length) {
      return STRING_ENUM_COLORS_RGBA[index]
    }
    return nullColor

  } else {
    const presentation = colorDetail.brackets as [number, number][]

    for (let i = 0; i < presentation.length; i++) {
      const max = presentation[i][1]

      if (value <= max) {

        // temporary solution so everything deos not break
        if (i >= 18) {
          const [r, g, b] = colors[17]
          return [r, g, b, opacity]
        }
        const [r, g, b] = colors[i]
        return [r, g, b, opacity]
      }
    }
  }

  return nullColor
}
