import { newEventBus } from '@/utils/eventBus.utils'
import GlobalUtils from '@/utils/global.utils'
import { Layer } from '@deck.gl/core'
import { GoogleMapsOverlay } from '@deck.gl/google-maps'
import {MapLayersEvent} from '@/libs/MapLayers/types'
import {validateDeckGlLeftClickEvent} from '@/utils/deck-gl.utils'
import {MVTLayer, MVTLayerProps} from '@deck.gl/geo-layers'
import DodonaBackend from '@/libs/loaders/dodona-backend/api-client'
import {ExtendedMVTLayerProps} from '@/services/RenderingService'

export type { Filter } from '@/libs/MapLayers/types'

export interface LayerSetEventArgs {
  id: string
  tags?: string[]
  layer: google.maps.Data
}

export interface DeckLayerSetEventArgs {
  id: string
  tags?: string[]
  layer: Layer
}

interface Cleaner {
  clear(): void
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function isCleaner(layer: any): layer is Cleaner {
  return typeof layer === 'object' && typeof layer?.clear === 'function'
}

/**
 * Layers plugin
 *
 * Provides an improved interface (in comparison to old filter & map) for
 * full control over data layers displayed on the map
 *
 * Responsibilities & goals:
 *  - seamless data layers management
 *  - control what is visible on the map
 *
 * Why not store (vuex?)
 *  - Google Maps provide its own data layer
 *  - no need for immutability
 *
 * @param layers
 */
export default class MapLayers {
  private layers = new Map<string, google.maps.MVCObject>()
  private map: google.maps.Map | null = null
  private deckOverlay: GoogleMapsOverlay | null = null
  private tags: Record<string, string[]> = {}
  private deckTags: Map<string, Set<string>> = new Map()
  private eventBus = newEventBus<MapLayersEvent>()

  /**
   * The amount of MVT layers currently being loaded
   * @private
   */
  private mvtLayersLoading = 0
  private abortSignals: AbortSignal[] = []

  constructor() {
    // Check requests for vector tile requests so we can show a loading indicator
    const originalFetch = window.fetch

    window.fetch = async (...args) => {
      let url = args[0]
      const init = args[1]

      try {
        if (url instanceof URL) {
          url = url.toString()
        } else if (url instanceof Request) {
          url = url.url
        }

        if (url.includes(DodonaBackend.VECTOR_DATASET_URL)) {
          if (this.mvtLayersLoading <= 0) {
            // Hook into the start of tile load
            this.onTileLoadStart()
          }

          // Save the signals to remove the loading indicator on abort
          if (init?.signal) {
            const signal: AbortSignal = init.signal
            signal.onabort = () => {
              this.onMvtLayerLoaded()
              // As long as one signal aborts, we can discard all the others
              this.abortSignals.forEach(as => as.onabort = null)
              this.abortSignals = []
            }

            this.abortSignals.push(signal)
          }
        }
      } catch (e) {
        console.error(e)
      }

      return originalFetch(...args)
    }
  }

  get events() {
    return this.eventBus
  }

  private get deckGlLayers(): Layer[] {
    if (!this.deckOverlay) {
      return []
    }

    return this.deckOverlay['props'].layers as Layer[]
  }

  private addAbortSignal(url: string, signal: AbortSignal) {
    this.abortSignals[url] = signal
  }

  onMvtLayerLoaded() {
    this.mvtLayersLoading--

    if (this.mvtLayersLoading <= 0) {
      this.events.$emit('mvt-load-end')
    }
  }

  setMap(map: google.maps.Map | null = null): void {
    if (this.map === map) {
      return
    }

    this.eventBus.$emit('map', map)
    this.map = map

    const deck = new GoogleMapsOverlay({
      layers: [],
      useDevicePixels: false,
      onHover: (info) => {
        const { x, y, layer, object, index } = info

        if (layer) {
          this.map?.setOptions({ draggableCursor: 'pointer' })
        } else {
          this.map?.setOptions({ draggableCursor: '' })
        }

        const pickedObjects = deck.pickMultipleObjects({
          x,
          y,
        }) ?? []

        if (index === -1 || !layer || !object || pickedObjects.length === 0) {
          this.eventBus.$emit('tooltip', { show: false })
          return
        }

        if (!((layer.props as any).hasTooltip)) {
          this.eventBus.$emit('tooltip', { show: false })
          return
        }

        this.eventBus.$emit('tooltip', { show: true, layer, features: pickedObjects.map(po => po.object) })
        return
      },
      onClick: (info, event) => {
        const { valid } = validateDeckGlLeftClickEvent(info, event)

        if (!valid) {
          return
        }

        this.eventBus.$emit('map-click', { info, event })
      },
    })

    this.deckOverlay = deck
    this.deckOverlay.setMap(this.map)
  }

  getMap(): google.maps.Map | null {
    return this.map
  }

  getRestriction(): google.maps.LatLngBounds | undefined {
    if (!this.map) {
      return
    }

    const mapRestriction = this.map.get('restriction') as
      | google.maps.MapRestriction
      | undefined
    if (!mapRestriction) {
      return
    }

    return mapRestriction.latLngBounds as google.maps.LatLngBounds
  }

  set(id: string, l: google.maps.MVCObject, tags?: string[]): void {
    this.setWithoutMap(id, l, tags)

    if (l instanceof google.maps.MVCArray) {
      l.forEach((e) => e.set('map', this.map))
    } else {
      l.set('map', this.map)
    }
  }

  removeDeckLayerByTags(tags: string[]): void {
    let hasChanges = false // Track if layers are modified
    if (this.deckOverlay) {

      let deckLayers = this.deckGlLayers
      tags.forEach(tag => {
        const deckTag = this.deckTags.get(tag)

        if (deckTag && this.deckOverlay) {

          const updatedDeckLayers = deckLayers.filter((item: Layer) => !deckTag.has(item.id))

          if (deckLayers.length !== updatedDeckLayers.length) {
            deckLayers = updatedDeckLayers
            hasChanges = true
          }
        }
      })

      if (hasChanges) {
        this.deckOverlay.setProps({
          layers: deckLayers,
        })
      }
    }
  }

  removeDeckLayerById(id: string): void {
    if (!this.deckOverlay) {
      return
    }

    let deckLayers = this.deckGlLayers

    deckLayers = deckLayers.filter((item: Layer) => item.id !== id)
    if (deckLayers.length !== this.deckOverlay['props'].layers.length) {
      this.deckOverlay.setProps({
        layers: deckLayers,
      })
    }
  }

  getDeckLayerById(id: string): Layer | undefined {
    if (!this.deckOverlay) {
      return
    }

    return this.deckGlLayers.find(l => l.id === id)
  }

  addOverlayTag(id: string, tag: string) {
    let deckTag = this.deckTags.get(tag)

    if (!deckTag) {
      this.deckTags.set(tag, new Set<string>())
      deckTag = this.deckTags.get(tag) as Set<string>
    }
    deckTag.add(id)

  }

  addDeckOverlayTop(layer: Layer, tag?: string) {
    if (!this.deckOverlay) {
      return
    }

    if (tag) {
      this.addOverlayTag(layer.id, tag)
    }

    const layers = ([...this.deckGlLayers, layer] as Layer[]).toSorted((l1, l2) => {
      const zIndex1 = (l1.props as unknown as ExtendedMVTLayerProps).zIndex ?? 0
      const zIndex2 = (l2.props as unknown as ExtendedMVTLayerProps).zIndex ?? 0
      return zIndex1 - zIndex2
    })

    this.deckOverlay.setProps({
      layers,
    })

    this.eventBus.$emit('deck-layer-set', { id: layer.id, layer, tags: tag ? [tag] : [] } as DeckLayerSetEventArgs)
  }

  // Use for clustered markers!
  setWithoutMap(
    id: string,
    layer: google.maps.MVCObject,
    tags?: string[],
  ): void {
    this.delete(id)
    this.layers.set(id, layer)
    this.tag(id, tags)

    this.eventBus.$emit('layer-set', { id, layer, tags } as LayerSetEventArgs)
  }

  tag(id: string, tags: string[] = []): void {
    if (!GlobalUtils.isProduction()) {
      console.log('tagging new layer', { id, tags })
    }

    tags.forEach((tag) => {
      if (this.tags[tag]) {
        this.tags[tag].push(id)
      } else {
        this.tags[tag] = [id]
      }
    })
  }

  getTagged(tags: string[]): google.maps.MVCObject[] {
    const taggedLayers: google.maps.MVCObject[] = []

    for (const tag of tags) {
      (this.tags[tag] || []).forEach((id) => {
        const layer = this.layers.get(id)
        if (layer) {
          taggedLayers.push(layer)
        }
      })
    }

    return taggedLayers
  }

  forEachTagged(
    tag: string,
    callback: (layer: google.maps.MVCObject, id: string) => void,
  ): void {
    (this.tags[tag] || []).forEach((id) => {
      const layer = this.layers.get(id)

      if (layer) {
        callback(layer, id)
      }
    })
  }

  get(id: string): google.maps.MVCObject | undefined {
    return this.layers.get(id)
  }

  has(id: string): boolean {
    return this.layers.has(id) || !!this.getDeckLayerById(id)
  }

  /**
   * Removes one or all map layers
   * @param id
   */
  delete(id: string): void {
    this.removeDeckLayerById(id)

    if (this.layers.has(id)) {
      const layer: google.maps.MVCObject | undefined = this.layers.get(id)

      if (layer instanceof google.maps.MVCArray) {
        layer.forEach((e) => {
          e.visible = false
          e.set('map', null)
        })
      } else if (layer instanceof google.maps.MVCObject) {
        layer.set('map', null)
      }

      if (isCleaner(layer)) {
        layer.clear()
      }

      this.layers.delete(id)
      this.events.$emit('layer-removed', { id })
    }

    this.gc(id)
  }


  /**
   * Deletes all layers using these tags
   * @param tags
   */
  deleteByTag(...tags: string[]): void {
    this.removeDeckLayerByTags(tags)
    tags.forEach((tag) => {
      this.forEachTagged(tag, (l: google.maps.MVCObject, id: string) => {
        this.delete(id)
      })
    })
    this.events.$emit('layer-removed', { tags })
  }


  gc(id?: string): void {
    for (const t in this.tags) {
      if (id) {
        // id removed, find if it was tagged anywhere and remove it..
        this.tags[t] = this.tags[t].filter((taggedId) => taggedId !== id)
      }

      if (this.tags[t].length === 0) {
        delete this.tags[t]
      }
    }
  }

  private onTileLoadStart() {
    if (!this.deckOverlay) {
      return
    }

    this.mvtLayersLoading = 0

    const layers = this.deckGlLayers
    layers.forEach(layer => {
      const props = layer.props as MVTLayerProps

      const isMvt = layer instanceof MVTLayer
      const isVisible = (props.maxZoom ?? 0) > (this.map?.getZoom() ?? -1)
        || (props.minZoom ?? 21) < (this.map?.getZoom() ?? 22)
      const { isLoaded } = layer

      this.mvtLayersLoading += isMvt && isVisible && !isLoaded ? 1 : 0
    })

    this.events.$emit('mvt-load-start')
  }
}
