import { GeometryTypes } from '@/utils/layers/layout-vector-config'
import { ColoringModeDatasetData, DatasetColoringAlgorithm } from '@/utils/layers/layout-config'

export type CategoryType = 'POI' | 'LAYER' | 'FEASIBILITY' | 'Public'
export type GenericWorkstationType = 'ORGANIZATION'


export interface GenericDatasetGeoJsonProperties {
  color: string
  label: string
  name: string,
  value: string,
}


export interface GenericDataset {
  dataset_id: number
  name: string
  description: string
  ui_location: CategoryType
  rendering_base_color: string
  icon_label: string
  min_zoom?: number
  accessType: GenericWorkstationType
  content_type: GeometryTypes
  coloring_property?: string,
  coloring_algorithm?: DatasetColoringAlgorithm,
  coloring_mode?: ColoringModeDatasetData,
}

export interface DatasetBatch {
  status: string
  error_message: string
  version: string
  process_start_time: string
  source_name: string
  dataset_name: string
}

export const DEFAULT_DATASET_COLOR = '#3548f4'
