import { DodonaMapEvents } from '@/utils/eventBus.utils'
import {MapLayerIds as Ids } from '@/utils/layers/layout-config'

export interface MapData {
  map: google.maps.Map | null
}

export enum GoogleMapTypes {
  roadmap = 'roadmap',
  satellite = 'satellite',
  // same as satelite but includes street numbers on map
  hybrid = 'hybrid',
}

/**
 * @deprecated
 *
 * TODO remove when deckGL is fully migrated
 */
export class MapLayerIds {
  public static moveEventCatcherPolygon = 'moveEventCatcherPolygon' as DodonaMapEvents
  public static workspaceRegionShadow = 'workspaceRegionShadow' as DodonaMapEvents
  public static workspaceGlobalShadow = 'workspaceGlobalShadow' as DodonaMapEvents
  public static workspaceSiteMoveId = Ids.moveSite as unknown as DodonaMapEvents
  public static infillLsoa = 'infill-lsoa0' as DodonaMapEvents
}
