import { PrimeVueConfiguration } from 'primevue/config'
import dialog from '@/presets/dialog'
import button from '@/presets/button'

// tw-appearance-none used for styling 
const defaultCheckboxStyle = 'tw-mr-2 tw-w-4 tw-h-4 tw-text-blue-400 tw-bg-gray-100 tw-border-gray-300 tw-rounded tw-focus:ring-blue-500 tw-dark:focus:ring-blue-600 tw-dark:ring-offset-gray-800 tw-focus:ring-2 tw-dark:bg-gray-700 tw-dark:border-gray-600'

export default {
  zIndex: {
    overlay: 4000,
    tooltip: 1040,
    overlaypanel: 1050,
  },
  // this removes only the classes used for it
  pt: {
    card: () => ({}),
    button: {
      ...button,
    },
    checkbox: (options) => {
      return ({
        root: options?.props?.disabled ? 'tw-flex tw-cursor-not-allowed' : 'tw-flex',
        input: `${defaultCheckboxStyle} ${options?.props?.disabled && 'tw-cursor-not-allowed'} tw-m-0`,
      })
    },
    dialog: dialog,
    datatable: () => ({
      headerRow: {
        root: 'table-custom-header',
      },
      tbody: {
        root: 'table-custom-body',
      },
    }),
    tabview: () => {
      return {
        nav: 'w-full h-full tw-flex tw-list-none tw-p-0 tw-full tab-custom tw-relative tw-border-t-0 tw-border-r-0 tw-border-l-0 tw-flex-1 tw-border-b-2 tw-border-solid tw-border-gray-100 tw-cursor-pointer',
        inkbar: 'tw-border-b-2 tw-border-solid border-blue tw-absolute -tw-bottom-0.5	',
        tab: 'w-full h-full tw-bg-neutral-200 tw-cursor-pointer',
        tabpanel: 'w-full h-full tw-bg-neutral-200',
        
      }
    },
    accordiontab: () => {
      return {
        root: 'tw-mb-1 tw-border-b-1 tw-border-solid tw-border-[#F6F6F6]',
        headerAction: 'tw-hover:tw-no-underline no-hover tw-w-full tw-m-0 tw-flex',
        header: 'tw-py-2 tw-bg-[#F6F6F6]', 
        headerTitle: 'tw-text-black tw-font-bold no-hover', 
        headerIcon: 'tw-text-black tw-rotate-90 tw-mr-2',
        content: 'tw-py-1',
      }
    },
    progressbar: {
      root: 'tw-bg-[#D9D9D9] tw-rounded-md tw-h-4 tw-f-full',
      value: 'tw-bg-[#1E3147] tw-rounded-md tw-h-full tw-transition-[width] tw-duration-1000 tw-ease-in-out',
      label: ({ props }) => {
        return [
          (props?.value || 0) > 65 ? 'tw-text-white' : 'tw-text-black',
          'tw-absolute',
          'tw-left-1/2',
          'tw-mt-0',
          'tw-text-xs',
        ]
      },
    },
    accordion: () => {
      return {
        tab: 'tw-py-2 tw-bg-[#F6F6F6]',
        accordiontab: 'tw-py-2 tw-bg-[#F6F6F6]',
      }
    },
    // POverlayPanel
    overlaypanel: () => {
      return {
        root: 'tw-tmt-2 tw-z-[1050]',
      }
    },
    directives: {
      tooltip: {
        root: ({ context }) => ({
          class: [
            // Position and Shadows
            'tw-absolute',
            'tw-p-fadein',
            'tw-max-w-[200px]',
            // Spacing
            {
              'tw-py-0 tw-px-1': (context === null ? undefined : context.right) || (context === null ? undefined : context.left) || !(context !== null && context.right) && !(context !== null && context.left) && !(context !== null && context.top) && !(context !== null && context.bottom),
              'tw-py-1 tw-px-0': (context === null ? undefined : context.top) || (context === null ? undefined : context.bottom),
            },
          ],
        }),
        arrow: ({ context }) => ({
          class: [
            // Position
            'tw-absolute',
            // Size
            'tw-w-0',
            'tw-h-0',
            // Shape
            'tw-border-transparent',
            'tw-border-solid',
            {
              'tw-border-y-[0.25rem] tw-border-r-[0.25rem] tw-border-l-0 tw-border-r-black': (context === null ? undefined : context.right) || !(context !== null && context.right) && !(context !== null && context.left) && !(context !== null && context.top) && !(context !== null && context.bottom),
              'tw-border-y-[0.25rem] tw-border-l-[0.25rem] tw-border-r-0 tw-border-l-black': context === null ? undefined : context.left,
              'tw-border-x-[0.25rem] tw-border-t-[0.25rem] tw-border-b-0 tw-border-t-black': context === null ? undefined : context.top,
              'tw-border-x-[0.25rem] tw-border-b-[0.25rem] tw-border-t-0 tw-border-b-black': context === null ? undefined : context.bottom,
            },
            // Spacing
            {
              '-tw-mt-1 ': (context === null ? undefined : context.right) || !(context !== null && context.right) && !(context !== null && context.left) && !(context !== null && context.top) && !(context !== null && context.bottom),
              '-tw-mt-1': context === null ? undefined : context.left,
              '-tw-ml-1': (context === null ? undefined : context.top) || (context === null ? undefined : context.bottom),
            },
          ],
        }),
        text: {
          class: [
            'tw-shadow-sm',
            'tw-shadow-black/25',
            'tw-p-3',
            'tw-bg-black',
            'dark:tw-bg-surface-700',
            'tw-text-white',
            'tw-leading-none',
            'tw-rounded-md',
            'tw-whitespace-pre-line',
            'tw-break-words',
          ],
        },
      },
    },
  },
} as PrimeVueConfiguration