import { MapRendererStyleOptions, RendererStyleProperties } from '@/libs/renderer/renderers'
import { LayerRole, UserRole } from '@/auth/roles'
import DodonaBackend from '@/libs/loaders/dodona-backend/api-client'
import { MinMax } from '@/types/app'
import {Feature, FeatureCollection, GeoJsonProperties, Geometry} from 'geojson'
import {ColorRGBA, GeometryTypes, GeometryZoomLevels} from '@/utils/layers/layout-vector-config'
import {PickingInfo} from 'deck.gl'
import { customUploadMarkerIcon } from '@/utils/sharedComponents.utils'
import { DEFAULT_DATASET_COLOR } from '@/models/admin/GenericDataset.model'

export enum MapLayerTags {
  uiLayer = 'ui-layer-tab',
  landAnalysis = 'land-analysis-tab',
  feasibilityTab = 'feasibility-tab',
  landAnalysisSearch = 'land-analysis-search',
  utilisationTabTag = 'ui-utilisation-tab',
  catchmentArea = 'catchment-area',
}

export enum MapLayerIds {
  dataCoverage = 'data-coverage',
  findLocations = 'find-locations-layer',
  moveSite = 'move-site',
  utilization = 'utilization',
  catchmentArea = 'catchment-area',
  regions = 'regions',
  areaInsight = 'area-insight',
}

interface DatasetDataOptions {
  name: string
  dataset: string
  genericApi?: boolean
  description?: string
  group?: string
  version?: string
  minZoom?: number
  role?: UserRole
  filterProperties?: PropertyFilter[]
}

export interface PropertyFilter {
  property_name: string
  filter: string[]
}


export abstract class DatasetData {
  public name: string
  public dataset: string
  public genericApi: boolean
  public description?: string
  public group?: string
  public version?: string
  public minZoom?: number
  public role?: UserRole
  public filterProperties: PropertyFilter[]

  protected constructor({
    name,
    dataset,
    description,
    group,
    version,
    minZoom,
    role,
    genericApi,
    filterProperties,
  }: DatasetDataOptions) {
    this.name = name
    this.dataset = dataset
    this.description = description
    this.group = group
    this.version = version
    this.minZoom = minZoom
    this.role = role
    this.genericApi = genericApi ?? false
    this.filterProperties = filterProperties ?? []
  }

  abstract getTag(): string

  isItemEnabled(currentZoom: number) {
    return !(this?.minZoom && currentZoom <= this?.minZoom)
  }

  getUniqueId(chunk: string | number = 0) {
    return this.name + '/' + chunk
  }

  updateFilter(filterProperties: PropertyFilter[]) {
    this.filterProperties = filterProperties
  }

  async fetchData(
    api: DodonaBackend,
    geometry: string,
    clipByRegionId?: number,
  ): Promise<FeatureCollection> {
    if (this.genericApi) {
      return await api.getGenericDataset(this.dataset, geometry, true, clipByRegionId)
    }

    return await api.dataset(this.dataset, geometry, this.version, clipByRegionId, this.filterProperties)
  }

  isDeckGlRenderer(): boolean {
    if (this instanceof GenericRuntimeDecisionData && this.coloring_mode) {
      if (this.content_type === GeometryTypes.Point || this.content_type === GeometryTypes.MultiPoint) {
        return [ColoringModeDatasetData.oneColorPolygon, ColoringModeDatasetData.colorFunction].includes(this.coloring_mode)
      }
      return true
    }
    return false
  }
}


export abstract class PolygonData extends DatasetData {
  rendererProperties: RendererStyleProperties
  mapStyle: MapRendererStyleOptions
  options: ClickableOptions | SimpleOption
  disableMinMax = false
  mapTypeStyle?: google.maps.MapTypeStyle[]
  paintStyleOptions?: Record<string, PolygonPaintStyle>

  protected constructor(private args: {
    name: string,
    dataset: string,
    description?: string,
    group?: string,
    minZoom?: number,
    version?: string,
    options: ClickableOptions | SimpleOption,
    paintStyle: PolygonPaintStyle,
    filterProperties?: PropertyFilter[],
    disableMinMax?: boolean,
    mapTypeStyle?: google.maps.MapTypeStyle[]
    genericApi?: boolean,
    role?: UserRole,
    paintStyleOptions?: Record<string, PolygonPaintStyle>
  }) {
    super(args)

    if (args.disableMinMax) {
      this.disableMinMax = true
    }
    this.mapTypeStyle = args.mapTypeStyle
    this.paintStyleOptions = args.paintStyleOptions
    this.options = args.options
    if (this.options instanceof ClickableOptions) {
      if (this.options.subOptions.length > 0) {
        this.selectOption(this.options.subOptions[0].name)
      } else {
        throw new Error('there arent any options defined')
      }
    }

    this.rendererProperties = args.paintStyle.rendererProp
    this.mapStyle = args.paintStyle.mapStyle
  }

  switchPolygonStyle(type: string) {
    const styles = this.paintStyleOptions && this.paintStyleOptions[type]
    if (!styles) {
      return
    }

    this.rendererProperties = styles.rendererProp
    this.mapStyle = styles.mapStyle
  }

  hasOptions(): boolean {
    return this.options instanceof ClickableOptions
  }

  selectOption(name: string) {
    if (this.options instanceof ClickableOptions) {
      this.options.selectOption(name)
    }
  }

  getSelectedOption() {
    if (this.options instanceof ClickableOptions) {
      return this.options.getSelectedOption()
    }
    throw new Error('object has only one option')
  }

  getOnlyOption(): SimpleOption {
    if (this.options instanceof SimpleOption) {
      return this.options
    }
    throw new Error('object has multiple options')
  }

  getFeatureProperty(): string {
    return this.hasOptions() ?
      this.getSelectedOption().featureProperty :
      this.getOnlyOption().featureProperty
  }

  /**
   * sets the feature property to the style. master stylist needs to be aware of this,
   * so it needs to happen before we do the rendering
   */
  setFeaturePropertyToStyle(): void {
    if (this.mapStyle.params) {
      this.mapStyle.params.featureProperty = this.getFeatureProperty()
    }
  }

  getUniqueId(chunk = 0) {
    return this.hasOptions() ?
      this.name + '/' + this.getSelectedOption().name + '/' + chunk :
      this.name + chunk
  }

  /**
   * @param api Backend instance to make the request with
   * @param geometry GeoJSON geometry in WKT format
   * @param clipByRegionId Region id to clip by, used for workspace clipping
   */
  async getMinMax(api: DodonaBackend, geometry: string, clipByRegionId?: number): Promise<MinMax> {
    if (this.genericApi) {
      return await api.getGenericApiMinMax(this.dataset, geometry, this.getFeatureProperty(), false)
    }

    return await api.minMax(this.dataset, geometry, clipByRegionId, false, this.getFeatureProperty())
  }

  setMinMax({ min, max }: MinMax) {
    if (!this.mapStyle.params) {
      return
    }

    this.mapStyle.params.min = min
    this.mapStyle.params.max = max
  }
}

export class PoiCheckboxData extends DatasetData {
  enableClusters: boolean
  icon?: string | google.maps.Icon
  uiIcon?: string | google.maps.Icon
  includeLabel?: boolean
  tag?: string

  public constructor(private args: {
    name: string,
    dataset: string,
    description?: string,
    group?: string,
    version?: string,
    minZoom?: number,
    icon?: string,
    uiIcon?: string,
    enableClusters?: boolean
    genericApi?: boolean
    appRole?: UserRole
    includeLabel?: boolean
    tag?: string
    filterProperties?: PropertyFilter[]
  }) {
    super({ ...args, genericApi: args.genericApi ?? true })
    this.enableClusters = args.enableClusters ?? false
    this.icon = args.icon
    this.uiIcon = args.uiIcon
    this.tag = args.tag
    this.includeLabel = args.includeLabel ?? true
  }

  getTag(): string {
    return this.tag ?? 'ui-poi'
  }
}

export class ParkingPolygonData extends PolygonData {
  tooltips: (Tooltip & { property: string })[]

  constructor(args: {
    name: string;
    dataset: string;
    description?: string;
    group?: string;
    minZoom?: number;
    version?: string;
    options: ClickableOptions | SimpleOption;
    paintStyle: PolygonPaintStyle;
    disableMinMax?: boolean;
    mapTypeStyle?: google.maps.MapTypeStyle[];
    filterProperties: PropertyFilter[];
    genericApi?: boolean;
    role?: LayerRole;
    tooltips?: (Tooltip & { property: string })[];
  }) {
    super(args)
    this.tooltips = args.tooltips ?? []
  }

  getTag(): string {
    return 'ui-parking'
  }

  hasOptions() {
    return false
  }

  getUniqueId() {
    return 'parking-data'
  }
}

export enum DatasetColoringAlgorithm {
  percentile = 'PERCENTILE',
  minMax = 'MIN_MAX',
  standardDev = 'ST_DEV',
  byString = 'BY_STRING'
}

export enum ColoringModeDatasetData {
  gradientAlgorithm = 'GRADIENT_ALGORITHM',
  oneColorPolygon = 'ONE_COLOR_POLYGON',
  oneColorIcon = 'ONE_COLOR_ICON',
  colorFunction = 'COLOR_FUNCTION',
}

export class ColoringFunctions<G extends Geometry = Geometry, P = GeoJsonProperties> {
  fillColor: ((f: Feature<G, P>) => ColorRGBA) | ColorRGBA
  lineColor: ((f: Feature<G, P>) => ColorRGBA) | ColorRGBA
  lineWidthMinPixels?: number
  pointRadius?: ((f: Feature<G, P>) => number) | number
  highlightColor?: ((info: PickingInfo) => ColorRGBA) | ColorRGBA

  constructor(
    fillColor: ((f: Feature<G, P>) => ColorRGBA) | ColorRGBA,
    lineColor: ((f: Feature<G, P>) => ColorRGBA) | ColorRGBA,
    pointRadius?: ((f: Feature<G, P>) => number) | number,
    lineWidthMinPixels?: number,
    highlightColor?: ((info: PickingInfo<Feature<G, P>>) => ColorRGBA) | ColorRGBA,
  ) {
    this.fillColor = fillColor
    this.lineColor = lineColor
    this.lineWidthMinPixels = lineWidthMinPixels
    this.pointRadius = pointRadius
    this.highlightColor = highlightColor
  }
}


export interface MapKeyDetail {
  description?: string
  params: MapKeyParameters[]
}

export interface MapKeyParameters {
  color: [string] | [string, string, string]
  titleLeft?: string
  titleRight: string
}


export class GenericRuntimeDecisionData extends DatasetData {
  color?: string | ColoringFunctions
  label?: string
  tag: string
  coloring_mode?: ColoringModeDatasetData
  content_type?: GeometryTypes
  hasTooltip?: boolean
  tooltipProperties?: Tooltip[]
  customTooltipComponent?: string
  icon?: string
  coloring_property?: string
  coloring_algorithm?: DatasetColoringAlgorithm
  mapKeyOverride?: MapKeyParameters[]
  pickable: boolean
  highlight: boolean
  highlightColor?: string | ((info: PickingInfo) => [number, number, number, number]) | [number, number, number, number]
  canOverlap: boolean

  public constructor(private args: {
    name: string,
    dataset: string,
    description?: string,
    group?: string,
    version?: string,
    minZoom?: GeometryZoomLevels,
    role?: UserRole
    includeLabel?: boolean
    filterProperties?: PropertyFilter[]
    color?: string | ColoringFunctions,
    label?: string,
    tag: string
    content_type: GeometryTypes
    coloring_mode?: ColoringModeDatasetData,
    // if it exists it will be picked from here instead of database
    coloring_property?: string,
    icon?: string,
    mapKeyOverride?: MapKeyParameters[]

    /**
     * If true, will display all feature properties in the tooltip
     */
    hasTooltip?: boolean

    /**
     * If present, will display only properties in this list in the tooltip
     */
    tooltipProperties?: Tooltip[]

    /**
     * If present, will render the component with that name as the tooltip
     */
    customTooltipComponent?: string

    /**
     * Trigger mouse events such as click, hover, etc. Defaults to true
     */
    pickable?: boolean

    /**
     * Highlight the layer on mouseover. Defaults to true
     */
    highlight?: boolean

    /**
     * If present, will be used to override the default highlight color.
     * Can be a hex RGB/RGBA color, a function or a RGBA array.
     */
    highlightColor?: string | ((info: PickingInfo) => [number, number, number, number]) | [number, number, number, number]

    /**
     * Whether the layer can have overlapping polygons. Marking it as such will send an array of all
     * polygons during hover events. Defaults to false.
     * */
    canOverlap?: boolean
  }) {
    super({
      name: args.name,
      dataset: args.dataset,
      description: args.description,
      group: args.group,
      version: args.version,
      minZoom: args.minZoom,
      role: args.role,
      genericApi: true,
      filterProperties: args.filterProperties,
    })
    this.coloring_mode = args.coloring_mode
    this.color = args.color
    this.label = args.label
    this.content_type = args.content_type
    this.tag = args.tag
    this.icon = args.icon
    this.mapKeyOverride = args.mapKeyOverride
    this.hasTooltip = args.hasTooltip ?? true
    this.tooltipProperties = args.tooltipProperties
    this.customTooltipComponent = args.customTooltipComponent
    this.coloring_property = args.coloring_property
    this.pickable = args.pickable ?? true
    this.highlight = args.highlight ?? true
    this.highlightColor = args.highlightColor
    this.canOverlap = args.canOverlap ?? false
  }

  getTag(): string {
    return this.tag
  }

  // TODO deprecated. Should be eliminated once we migrate icons to deck.gl
  exportAppropriateDatasetData(): DatasetData {
    return new PoiCheckboxData({
      name: this.name,
      dataset: this.dataset,
      description: this.description,
      group: this.group,
      icon: customUploadMarkerIcon(typeof this.color === 'string' ? this.color : DEFAULT_DATASET_COLOR , (this.label || this.name).substring(0, 2)),
      version: this.version,
      minZoom: this.minZoom,
      genericApi: this.genericApi,
      filterProperties: this.filterProperties,
      appRole: this.role,
      enableClusters: false,
      includeLabel: true,
      tag: this.tag,
    })
  }
}


export class LayerPolygonData extends PolygonData {
  tooltips: (Tooltip & { property: string })[]
  prop: string | undefined
  private customTooltip: boolean

  constructor(args: {
    name: string;
    dataset: string;
    description?: string;
    group?: string;
    minZoom?: number;
    version?: string;
    options: ClickableOptions | SimpleOption;
    paintStyle: PolygonPaintStyle;
    disableMinMax?: boolean;
    prop?: string;
    mapTypeStyle?: google.maps.MapTypeStyle[];
    genericApi?: boolean
    role?: LayerRole
    tooltips?: (Tooltip & { property: string })[],
    paintStyleOptions?: Record<string, PolygonPaintStyle>
    customTooltip?: boolean
  }) {
    super(args)
    this.tooltips = args.tooltips ?? []
    this.customTooltip = args.customTooltip ?? false
    if (args.prop) {
      this.prop = args.prop
    }
  }

  async getMinMax(api: DodonaBackend, geometry: string, clipByRegionId?: number): Promise<MinMax> {
    if (this.genericApi) {
      return await api.getGenericApiMinMax(this.dataset, geometry, this.getFeatureProperty(), false)
    }

    return await api.minMax(this.dataset, geometry, clipByRegionId, false, this.getFeatureProperty())
  }

  getTag(): string {
    return 'ui-layer-tab'
  }

  getTooltips() {
    return this.tooltips
  }

  showCustomTooltip(): boolean {
    return this.customTooltip
  }
}

export const smartPlanningPolygonDataTags = 'ui-smart-planning-tab'

export class SmartPlanningPolygonData extends LayerPolygonData {
  getTag(): string {
    return smartPlanningPolygonDataTags
  }
}

export interface Tooltip {
  text: string
  property: string
  valueTransform?: (value: any, feature: google.maps.Data.Feature | Record<string, any>) => string
  rounded?: number

  /**
   * Additional CSS for the label element
   */
  labelStyle?: Partial<CSSStyleDeclaration>

  /**
   * Additional CSS for the value element
   */
  valueStyle?: Partial<CSSStyleDeclaration>
}

export class SimpleOption {
  featureProperty: string
  tooltip?: Tooltip

  constructor(featureProperty: string, tooltip?: Tooltip) {
    this.featureProperty = featureProperty
    this.tooltip = tooltip
  }
}


export class ClickableOptions {
  name?: string
  subOptions: SubOption[]
  selectedOption?: SubOption

  constructor(name: string, subOptions: SubOption[]) {
    if (subOptions.length <= 1) {
      throw new Error('You need to provide at least 2 options')
    }
    this.name = name
    this.subOptions = subOptions
  }

  selectOption(name: string) {
    this.selectedOption = this.subOptions.find(subOption => subOption.name === name)
  }

  getSelectedOption(): SubOption {
    if (!this.selectedOption) {
      throw new Error('No option has been selected')
    }
    return this.selectedOption
  }
}

export interface SubOption {
  name: string
  featureProperty: string
}

export interface PolygonPaintStyle {
  rendererProp: RendererStyleProperties,
  mapStyle: MapRendererStyleOptions,
}
