import {Feature, Polygon} from 'geojson'
import {HammerInput, MjolnirEventWrapper} from 'mjolnir.js/src/types'
import {PickingInfo} from 'deck.gl'

interface ValidEvent<T> {
  valid: true
  feature?: Feature<Polygon, T>
  coordinate: [number, number]
}

interface InvalidEvent {
  valid: false,
  feature: undefined
  coordinate: undefined
}

/**
 * Check that it's a left click event and that no important data is missing
 * @param info
 * @param event
 */
export function validateDeckGlLeftClickEvent<T>(
  info: PickingInfo,
  event: MjolnirEventWrapper<HammerInput>,
): ValidEvent<T> | InvalidEvent {
  try {
    if ((event.srcEvent as any).domEvent.button === 2) {
      return {
        valid: false,
        feature: undefined,
        coordinate: undefined,
      }
    }
  } catch (e) {
    // This will probably never happen and is just a precaution. We don't really care if it errors,
    // we can just continue normally and treat it as a left click
    console.error('error checking right click', e)
  }

  const feature = info.object as Feature<Polygon, T> | undefined
  const coordinate = info.coordinate as [number, number] | undefined
  if (!coordinate) {
    return {
      valid: false,
      feature: undefined,
      coordinate: undefined,
    }
  }

  return {
    feature,
    coordinate,
    valid: true,
  }
}