import axios from 'axios'
import { Position } from 'geojson'
import { RouteLocation } from 'vue-router'
import us from 'us'
import GlobalUtils from '@/utils/global.utils'
import { CountryCode } from '@/types/app'

interface State {
  fips: string,
}

interface AddressResult {
  location: string
  state?: string
  stateId?: number
}

interface ReverseGeocodingLocation {
  lat: number
  lng: number
}

interface Response {
  results: Result[]
}

interface Result {
  formatted_address: string
  types: string[]
  address_components: {
    long_name: string
    short_name: string
    types: string[]
  }[]
}

function extractAddressByType(rr: Result[], type: string): AddressResult | undefined {
  for (const r of rr || []) {
    if (r.types.includes(type)) {
      const stateCode = r.address_components?.[0]?.short_name

      const state = stateCode in us.states ? us.states[stateCode] : undefined as State | undefined
      const stateId = state ? state.fips * 1000 : undefined

      return {
        stateId,
        state: stateCode,
        location: r.formatted_address,
      }
    }
  }
}

export function revGeoCode(location: ReverseGeocodingLocation | google.maps.LatLng): Promise<Response> {
  let lat
  let lng

  if (location instanceof google.maps.LatLng) {
    lat = location.lat()
    lng = location.lng()
  } else {
    lat = location.lat
    lng = location.lng
  }

  return axios
    .get<Response>('https://maps.googleapis.com/maps/api/geocode/json', {
      params: {
        latlng: `${lat},${lng}`,
        key: import.meta.env.VITE_APP_GOOGLE_MAPS_API_KEY,
        language: 'en',
      },
    })
    .then(({ data }) => data)
}

export async function revGeoCodeCurrentView(c: ReverseGeocodingLocation | google.maps.LatLng): Promise<AddressResult | undefined> {
  const data = await revGeoCode(c)

  // Needed for correct display of location on the overlay component on map
  switch (GlobalUtils.getAppCountry()) {
    case CountryCode.US:
      return (
        extractAddressByType(data?.results, 'administrative_area_level_1') ||
        extractAddressByType(data?.results, 'administrative_area_level_2') ||
        extractAddressByType(data?.results, 'establishment') ||
        extractAddressByType(data?.results, 'country')
      )
    default:
      return (
        extractAddressByType(data?.results, 'administrative_area_level_2') ||
        extractAddressByType(data?.results, 'administrative_area_level_1') ||
        extractAddressByType(data?.results, 'establishment') ||
        extractAddressByType(data?.results, 'country')
      )
  }
}

export interface Address {
  street_number?: string
  route?: string
  postal_code?: string
  country?: string
}

/**
 * Decodes street number, route, postal code and country
 * from Google's reverse geocode service response payload
 *
 * @param c
 */
export async function revGeoCodeStreetPost(c: ReverseGeocodingLocation | google.maps.LatLng): Promise<Address> {
  return revGeoCode(c).then(({ results }) => {
    const address: Address = {}

    for (let r = 0; r < results.length; r++) {
      const { address_components } = results[r]

      for (let a = 0; a < address_components.length; a++) {
        const ac = address_components[a]

        switch (true) {
          case ac.types.includes('street_number') && !address.street_number:
            address.street_number = ac.long_name
            break

          case ac.types.includes('route') && !address.route:
            address.route = ac.long_name
            break

          case ac.types.includes('postal_code') && !address.postal_code:
            address.postal_code = ac.long_name
            break

          case ac.types.includes('country') && !address.country:
            address.country = ac.long_name
            break
        }
      }
    }

    return address
  })
}

export function pairCoordinates(c: unknown): number {
  let lat = 0
  let lng = 0

  if (Array.isArray(c)) {
    lng = c[0]
    lat = c[1]
  } else if (c instanceof google.maps.LatLng) {
    lat = c.lat()
    lng = c.lng()
  }

  return (((lat * 1e7) << 16) & 0xffff0000) | ((lng * 1e7) & 0x0000ffff)
}

/**
 * Read zoom and position from URL and apply it to the map
 */
export function setMapPositionFromQuery(map: google.maps.Map, route: RouteLocation): void {
  const { lat, lng, zoom } = route.query as {
    lat?: string
    lng?: string
    zoom?: string
  }

  if (lat && lng && zoom) {
    const position = new google.maps.LatLng(parseFloat(lat), parseFloat(lng))
    const zoomInt = parseInt(zoom)
    const center = map.getCenter()

    if (center && !position.equals(center)) {
      map.setCenter(position)
    }

    if (map.getZoom() !== zoomInt) {
      map.setZoom(zoomInt)
    }
  }
}

export function getWorldBbox(): Position[][] {
  return [
    [
      [180, 90],
      [180, -90],
      [-180, -90],
      [-180, 90],
      [180, 90],
    ],
  ]
}

export function getWorldBboxAsLatLng(): google.maps.LatLngLiteral[] {
  return [
    { lat: -85, lng: -179 },
    { lat: 85, lng: -179 },
    { lat: 85, lng: 179 },
    { lat: -85, lng: 179 },
    { lat: -85, lng: 0 },
    { lat: -85, lng: -179 },
  ]
}
