import { ColoringFunctions, PolygonPaintStyle } from '@/utils/layers/layout-config'
import { RendererStyleProperties } from '@/libs/renderer/renderers'
import { AllEvOptions } from './layers-uk'
import { Feature, GeoJsonProperties, Geometry } from 'geojson'

export const mapOverlayZIndex = {
  mapCoverageStyle: 5003,
  mapWorkspaceShadow: 5002,
  mapWorkspaceRegionPolygon: 5002,
  mapWorkspaceWards: 5001,
  mapWorkspaceLocalFactory: 5004,
  mapWorkspaceFreehold: 5005,
  mapWorkspaceLeasehold: 5006,
  mapLayer: 5100,
  mapLayerHover: 5101,
  mapLayerSelected: 5102,
  mapCatchmentLayer: 5103,
  mapLayerCatchmentHover: 5104,
  tooltips: 5100,
  customOverlays: 5101,
}

export const INFILL_LSOA_STYLE: PolygonPaintStyle = {
  rendererProp: {
    zIndex: mapOverlayZIndex.mapLayer,
    strokeColor: '#00B2FF',
    strokeOpacity: 1,
    strokeWeight: 4,
    fillOpacity: 0,
  },
  mapStyle: {
    type: 'map',
  },
}

export const INFILL_LSOA_WHITE_STYLE: PolygonPaintStyle = {
  rendererProp: {
    zIndex: mapOverlayZIndex.mapLayer,
    fillColor: '#4169e1',
    strokeColor: '#ffffff',
    strokeWeight: 4,
    strokeOpacity: 1,
  },
  mapStyle: {
    type: 'map',
  },
}

export const PARKING_STYLE: PolygonPaintStyle = {
  rendererProp: {
    zIndex: mapOverlayZIndex.mapLayer,
    fillColor: '#4169e1',
    strokeColor: '#15224a',
    strokeWeight: 1,
    strokeOpacity: 0.2,
  },
  mapStyle: {
    type: 'map',
    params: {
      scale: 'logarithmic',
      range: ['0.2', '0.4', '0.6', '0.8'],
      default: '0',
      styleProperty: 'fillOpacity',
    },
  },
}

export const LAND_ANALYSIS_STYLE_SELECTED: RendererStyleProperties = {
  zIndex: mapOverlayZIndex.mapLayerSelected,
  fillOpacity: 0.8,
}

export const PRETTY_BLUE_STYLE_CIRCLE =
  {
    zIndex: mapOverlayZIndex.mapLayer,
    strokeColor: '#FFFFFF',
    strokeOpacity: 2,
    strokeWeight: 3,
    fillColor: '#00B2FF',
    fillOpacity: 1,
    radius: 5,
  }

export const PRETTY_BLUE_STYLE_POLYGON =
  {
    zIndex: mapOverlayZIndex.mapLayer,
    strokeColor: '#00B2FF',
    strokeOpacity: 1,
    strokeWeight: 5,
    fillColor: '#00B2FF',
    fillOpacity: 0.3,
  }

export const PRETTY_BLUE_EV_COUNT: (feature: AllEvOptions) => PolygonPaintStyle = (feature) => {
  return {
    rendererProp: {
      zIndex: mapOverlayZIndex.mapLayer,
      fillColor: '#4169e1',
      strokeColor: '#ffffff',
      strokeWeight: 1,
      strokeOpacity: 1,
    },
    mapStyle: {
      type: 'map',
      params: {
        min: 0,
        max: 1,
        featureProperty: feature,
        range: ['0.2', '0.4', '0.6', '0.8'],
        default: '0',
        styleProperty: 'fillOpacity',
      },
    },
  }
}

export const URBAN_FOX_PLYMOUTH_LSOAS: PolygonPaintStyle = {
  rendererProp: {
    zIndex: mapOverlayZIndex.mapLayer,
    fillOpacity: 0.3,
    strokeWeight: 0.9,
  },
  mapStyle: {
    type: 'map',
    params: {
      map: {
        'A': {
          'fillColor': '#ed840c',
          'strokeColor': '#ed840c',
        },
        'B': {
          'fillColor': '#0c88ed',
          'strokeColor': '#0c88ed',
        },
      },
    },
  },
}

function getOffstreetColorWithAlpha(propertyValue: string | undefined, alpha: number): [number, number, number, number] {
  if (propertyValue === 'low_probability') {
    return [250, 0, 0, alpha]
  } else if (propertyValue === 'high_probability') {
    return [0, 255, 0, alpha]
  } else if (propertyValue === 'non_residential' || propertyValue === 'mainly_non_residential_area') {
    return [131, 173, 191, alpha]
  } else if (propertyValue === 'mixed') {
    return [252, 231, 125, alpha]
  } else {
    return [0, 0, 0, 0]
  }
}

export const offstreetParkingColoring = new ColoringFunctions(
  (feature: Feature<Geometry, GeoJsonProperties>): [number, number, number, number] => {
    return getOffstreetColorWithAlpha(feature.properties?.offp_cat, 80)
  },
  (feature: Feature<Geometry, GeoJsonProperties>): [number, number, number, number] => {
    return getOffstreetColorWithAlpha(feature.properties?.offp_cat, 230)
  },
)


export const offstreetParkingColoringResidential = new ColoringFunctions(
  (feature: Feature<Geometry, GeoJsonProperties>): [number, number, number, number] => {
    return getOffstreetColorWithAlpha(feature.properties?.name, 80)
  },
  (feature: Feature<Geometry, GeoJsonProperties>): [number, number, number, number] => {
    return getOffstreetColorWithAlpha(feature.properties?.name, 230)
  },
)

const getColorForClusterV1 = (propertyValue: string, alpha: number): [number, number, number, number] => {
  if (propertyValue === 'mixed; shop oriented, food&drink, everyday') {
    return [175, 3, 151, alpha]
  } else if (propertyValue === 'culture, historic, attraction') {
    return [255, 215, 0, alpha]
  } else if (propertyValue === 'health') {
    return [50, 205, 50, alpha]
  } else if (propertyValue === 'mixed: food&drink, nightlife, community oriented, everyday') {
    return [255, 105, 180, alpha]
  } else if (propertyValue === 'sport') {
    return [255, 69, 0, alpha]
  } else if (propertyValue === 'accomodation: nature, adventure') {
    return [34, 139, 34, alpha]
  } else if (propertyValue === 'mixed: significant parking, outdoor, family friendly, not so shop oriented') {
    return [147, 112, 219, alpha]
  } else if (propertyValue === 'attractions') {
    return [255, 140, 0, alpha]
  } else if (propertyValue === 'accomodation: hotels, resorts') {
    return [32, 178, 170, alpha]
  } else if (propertyValue === 'education') {
    return [30, 144, 255, alpha]
  } else {
    return [255, 255, 255, alpha]
  }
}

export const clusteringV1Coloring = new ColoringFunctions(
  (feature: Feature<Geometry, GeoJsonProperties>): [number, number, number, number] => {
    const propertyValue = feature.properties?.cluster || ''
    return getColorForClusterV1(propertyValue, 80)
  },
  (feature: Feature<Geometry, GeoJsonProperties>): [number, number, number, number] => {
    const propertyValue = feature.properties?.cluster || ''
    return getColorForClusterV1(propertyValue, 230)
  },
)


const getColorForV2Cluster = (propertyValue: string, alpha: number): [number, number, number, number] => {
  if (propertyValue === 'culture, historic, attraction') {
    return [255, 215, 0, alpha]
  } else if (propertyValue === 'health') {
    return [50, 205, 50, alpha]
  } else if (propertyValue === 'sport') {
    return [255, 69, 0, alpha]
  } else if (propertyValue === 'accommodation') {
    return [175, 3, 151, alpha]
  } else if (propertyValue === 'mixed: not so shop oriented, with parking, nightlife, accommodation') {
    return [147, 112, 219, alpha]
  } else if (propertyValue === 'attraction') {
    return [255, 140, 0, alpha]
  } else if (propertyValue === 'mixed: shops, retail, town centres') {
    return [32, 178, 170, alpha]
  } else if (propertyValue === 'mixed: residential, small shops and amenities') {
    return [34, 139, 34, alpha]
  } else if (propertyValue === 'education') {
    return [30, 144, 255, alpha]
  } else {
    return [0, 0, 0, alpha]
  }
}

export const clusteringV2Coloring = new ColoringFunctions(
  (feature: Feature<Geometry, GeoJsonProperties>): [number, number, number, number] => {
    const propertyValue = feature.properties?.cluster || ''
    return getColorForV2Cluster(propertyValue, 80)
  },
  (feature: Feature<Geometry, GeoJsonProperties>): [number, number, number, number] => {
    const propertyValue = feature.properties?.cluster || ''
    return getColorForV2Cluster(propertyValue, 230)
  },
)


export const floodZoneUkFunction = (feature: Feature<Geometry, GeoJsonProperties>): [number, number, number, number] => {
  const layerUk = feature.properties?.layer
  const layerDe = feature.properties?.probability
  if (layerUk === 'Flood Zone 2' || layerDe === 'medium') {
    return [164, 189, 222, 100]
  } else if (layerUk === 'Flood Zone 3' || layerDe === 'high') {
    return [118, 161, 210, 150]
  } else {
    return [0,0,0,255]
  }
}

export const floodZoneUsFunction = (feature: Feature<Geometry, GeoJsonProperties>): [number, number, number, number] => {
  const layer = feature.properties?.probability
  if (layer === 'low') {
    return [164, 189, 222, 100]
  } else if (layer === 'high') {
    return [118, 161, 210, 150]
  } else {
    return [0,0,0,255]
  }
}
