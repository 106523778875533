import {
  DatasetData,
  GenericRuntimeDecisionData,
  ParkingPolygonData,
  PropertyFilter,
  SimpleOption,
} from '@/utils/layers/layout-config'
import { PARKING_STYLE } from '@/utils/layers/layerStyles.utils'
import { CountryCode } from '@/types/app'
import { layersUK } from '@/utils/layers/layers-uk'
import { layersUS } from '@/utils/layers/layers-us'
import GlobalUtils from '@/utils/global.utils'
import { layersDE } from '@/utils/layers/layers-de'
import { RoleManager } from '@/libs/RoleManager'
import { Organization } from '@/auth/roles'
import { DEFAULT_DATASET_COLOR, GenericDataset } from '@/models/admin/GenericDataset.model'

export const featureOverlayDisabled = (organizationStore: RoleManager, organizationName: Organization, dataset: string): boolean => {
  switch (GlobalUtils.getAppCountry()) {
    case CountryCode.US:
      return layersUS.some(layers => layers.dataset === dataset) 
    case CountryCode.DE:
      return layersDE.some(layers => layers.dataset === dataset) 
    case CountryCode.UK:
      return layersUK(organizationStore, organizationName).some(layers => layers.dataset === dataset)  
    default:
      return false 
  }
}

export function layerConfiguration(organizationStore: RoleManager, organizationName: Organization): DatasetData[] {
  switch (GlobalUtils.getAppCountry()) {
    case CountryCode.US:
      return layersUS
    case CountryCode.DE:
      return layersDE
    case CountryCode.UK:
      return layersUK(organizationStore, organizationName)
    default:
      return []
  }
}

export function getGenericLayerConfiguration(g: GenericDataset): GenericRuntimeDecisionData {
  return new GenericRuntimeDecisionData({
    name: g.name,
    dataset: g.name,
    color: g.rendering_base_color ?? DEFAULT_DATASET_COLOR,
    label: g.icon_label,
    tag: g.ui_location === 'POI' ? 'ui-poi' : 'ui-layer-tab',
    content_type: g.content_type,
    coloring_mode: g.coloring_mode,
    minZoom: g.min_zoom,
  })
}

export const parkingMaxZoom = 9

export function getParkingPolygon(parkingOperators: string[], operatorsCategories: string[]) {
  const filterPropertis: PropertyFilter[] = []

  if (parkingOperators.length > 0) {
    filterPropertis.push({
      property_name: 'parking_operator_class',
      filter: parkingOperators,
    })
  }
  if (operatorsCategories.length > 0) {
    filterPropertis.push({
      property_name: 'parking_category_class',
      filter: operatorsCategories,
    })
  }

  return new ParkingPolygonData({
    name: 'Parking data',
    group: 'parking',
    dataset: 'PARKOPEDIA_PARKINGS',
    filterProperties: filterPropertis,
    minZoom: parkingMaxZoom,
    disableMinMax: true,
    options: new SimpleOption('parking'),
    paintStyle: PARKING_STYLE,
  })
}

