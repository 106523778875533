import {
  ExportType,
  SiteProperties,
  SiteScoreDetail,
  StatusIcon,
  Workspace,
  WorkspaceHistory,
  WorkspaceIndexItem,
  WorkspaceSite,
} from '@/types/workspace'
import DodonaBackend from './loaders/dodona-backend/api-client'
import {ChargePoint, CountryCode} from '@/types/app'
import {num2LatLngLit} from './helpers'
import {pairCoordinates, revGeoCodeStreetPost} from './gmaps'
import * as icon from './icons'
import {AxiosRequestConfig} from 'axios'
import {User} from '@/libs/loaders/dodona-backend/types'
import {ScoreColorPresentation, ScoreMissingReason} from '@/models/SiteSelection/SiteScore.model'
import {FeatureCollection, Point} from 'geojson'

export const defaultMarkerIcon = {
  scaledSize: new google.maps.Size(40, 40),
  labelOrigin: new google.maps.Point(22, 54),
  url: icon.inline({ id: 'default', fill: '#FD003A', stroke: '#FFFFFF' }),
}

export const rejectedMarkerIcon = {
  ...defaultMarkerIcon,
  url: icon.inline({ id: 'default', fill: '#203248', stroke: '#FFFFFF' }),
}

export function siteMarkerIcon(site: WorkspaceSite): google.maps.Icon {
  switch (site.properties.status) {
    case 'Rejected':
      return rejectedMarkerIcon

    default:
      return defaultMarkerIcon
  }
}

export function initDownload(
  filename: string,
  payload: unknown,
  fileType: ExportType = 'json',
): void {
  const el = document.createElement('a')

  switch (fileType) {
    case 'csv':
      el.setAttribute(
        'href',
        'data:text/csv;charset=utf-8,' + encodeURIComponent(payload as string),
      )
      break
    case 'xlsx':
      el.href = window.URL.createObjectURL(new Blob([payload as Blob]))
      break
    default:
      el.setAttribute(
        'href',
        'data:application/json;charset=utf-8,' +
        encodeURIComponent(JSON.stringify(payload)),
      )
      break
  }

  el.setAttribute('download', filename)
  el.style.display = 'none'
  document.body.appendChild(el)
  el.click()
  document.body.removeChild(el)
}

export type SiteDensity = 'very_low' | 'low' | 'mid' | 'high' | 'legacy'

/**
 * Request type when generating sites within a workspace via either generate sites or infill
 */
export interface GenerateSitesParams {
  workspaceId: string
  geometry?: string
  assessmentName?: string
  resultSize?: number
  lsoaId?: string,
  resultDensity?: SiteDensity
  cleanup?: () => void
}

export interface LsoaSiteParams {
  workspaceId: string
  geometry?: string
  assessmentName?: string 
  show: boolean
}

export interface RequestSitesResponse {
  message: string | null
  sites: WorkspaceSite[] | FeatureCollection<Point, {}>
}

export interface CreateWorkspaceParams {
  country_code: CountryCode
  name: string
  notes?: string
  status?: 'Active'
  region_id: number
  owner_id?: string
  main_contact?: string
  planning_level: number
  fleet?: string | number
}

export interface EditWorkspaceParams {
  name?: string
  owner_id?: string | null
  main_contact?: string | null
  status_id?: string
}

export interface EditSiteParams {
  status?: string
  status_id?: string
  notes?: string
  description?: string
  source?: string
  source_id?: string
  street?: string
  post?: string
  passive_bays?: number
}

export interface ConfirmSiteParams {
  workspace_id: string
  coordinates: google.maps.LatLngLiteral
  post: string
  street: string
  aggregate_score: number
  score: SiteScoreDetail[]
  assessment_name: string | undefined
  score_missing_reason?: ScoreMissingReason
}

const apiV2Prefix = '/api/v2/'
const workspaceApiUrl = apiV2Prefix + 'workspace'
const siteApiUrl = apiV2Prefix + 'site'

export async function getWorkspaces(api: DodonaBackend, onProgress: (value: number) => any): Promise<WorkspaceIndexItem[]> {
  return api.client
    .get<WorkspaceIndexItem[]>(workspaceApiUrl, {
      onUploadProgress(progressEvent) {
        onProgress(Math.round((progressEvent.loaded * 100) / (progressEvent?.total || 100)))
      },
    })
    .then((res) => res.data as WorkspaceIndexItem[])
}

export async function getWorkspace(
  api: DodonaBackend,
  id: string,
): Promise<Workspace> {
  return api.client.get<Workspace>(`${workspaceApiUrl}/${id}`).then((res) => res.data)
}

export async function copyWorkspace(
  api: DodonaBackend,
  id: string,
): Promise<WorkspaceIndexItem> {
  return api.client
    .post(`${workspaceApiUrl}/copy/${id}`)
    .then((res) => res.data)
}

/**
 * @return Workspace ID
 */
export async function createWorkspace(
  api: DodonaBackend,
  req: CreateWorkspaceParams,
): Promise<string> {
  const config: AxiosRequestConfig = {
    url: workspaceApiUrl,
    // Increase timeout as it's a lengthy operation
    timeout: 1000 * 600,
    method: 'POST',
    data: req,
  }

  return api.client.request(config).then((res) => res.data as string)
}

/**
 * @return Workspace ID
 */
export async function editWorkspace(
  api: DodonaBackend,
  id: string,
  req: EditWorkspaceParams,
): Promise<Workspace> {
  return api.client
    .put(`${workspaceApiUrl}/${id}`, req)
    .then((res) => res.data as Workspace)
}


export interface OptionalSiteParams {
  notes?: string
  description?: string
  source?: string
  status?: string
  status_id?: string
  statusIcon?: StatusIcon
}

export async function moveSite(
  api: DodonaBackend,
  coordinates: google.maps.LatLngLiteral,
  site_id: string,
  options?: OptionalSiteParams,
): Promise<WorkspaceSite> {
  return api.client
    .post(siteApiUrl + '/move-site', {
      site_id,
      coordinates,
      ...options,
    })
    .then((res) => res.data)
}


export async function editSite(
  api: DodonaBackend,
  id: string,
  req: EditSiteParams,
): Promise<WorkspaceSite> {
  return api.client
    .put(`${siteApiUrl}/${id}`, req)
    .then((res) => res.data as WorkspaceSite)
}

export async function deleteSite(
  api: DodonaBackend,
  id: string,
  reason = 'Deleted manually',
): Promise<void> {
  return api.client
    .delete(`${siteApiUrl}/${id}`, {
      data: {
        deleted_reason: reason,
      },
    })
    .then((res) => res.data)
}

export async function confirmSite(
  api: DodonaBackend,
  req: ConfirmSiteParams,
): Promise<WorkspaceSite> {
  return api.client
    .post(`${siteApiUrl}/predicted-site`, req)
    .then((res) => res.data as WorkspaceSite)
}

export async function requestGeneratedSites(
  api: DodonaBackend,
  req: GenerateSitesParams,
  onProgress: (value: number) => void,
  progressCount: number = 1000,
): Promise<RequestSitesResponse> {
  const config: AxiosRequestConfig = {
    url: `${siteApiUrl}/workspace-prediction-ordered`,
    method: 'POST',
    timeout: 1000 * 600,
    data: {
      workspace_id: req.workspaceId,
      assessment_name: req.assessmentName,
      result_size: req.resultSize,
      geometry: req.geometry,
      result_density: req.resultDensity,
    },
  }
  let count = 0
  const clearIntervalId = setInterval(() => {
    count = Math.min(100, Math.max(0, count + 1))
    onProgress(count)
  }, progressCount)

  return api.client
    .request(config)
    .then((res) => {
      clearInterval(clearIntervalId)
      return res.data as RequestSitesResponse
    })
}

export async function requestInfilledSites(
  api: DodonaBackend,
  req: GenerateSitesParams,
): Promise<RequestSitesResponse> {
  const config: AxiosRequestConfig = {
    url: `${siteApiUrl}/workspace-infill`,
    method: 'POST',
    timeout: 1000 * 1200,
    data: {
      workspace_id: req.workspaceId,
      assessment_name: req.assessmentName,
      geometry: req.geometry,
      lsoa_id: req.lsoaId,
      result_density: req.resultDensity,
    },
  }

  return api.client
    .request(config)
    .then((res) => res.data as RequestSitesResponse)
}

export function convertChargePointToSite(coordinates: [number, number], properties: ChargePoint): WorkspaceSite {
  const siteProps = {
    created_at: new Date(),
    label: 'Untitled',
    notes: '',
    status: 'proposed',
    source: 'generated',
    projected_coordinate_system: [-1, -1],
    assessment_name: '',
    iteration_number: 1,
    charge_point_id: properties.charge_point_id,
    post: 'Pending ...',
    score: [],
    chargers: [],
    street: 'Pending ...',
    aggregate_score: 0,
    socket_count: 0,
    sockets: 0,
    status_id: '',
    passive_bays: null,
    statusIcon: 'Proposed',
    charge_point_count: 0,
    checklist_item_count: 0,
    created_by: '',
    aggregate_score_name: 'No data available',
    aggregate_score_color: ScoreColorPresentation.GREY,
    locked: false,
  }

  return {
    id: '' + pairCoordinates(coordinates),
    type: 'Feature',
    geometry: {
      type: 'Point',
      coordinates,
    },
    properties: {
      ...siteProps,
      ...properties,
    } as unknown as SiteProperties,
  }
}

export async function loadExtraSiteDetails(s: WorkspaceSite): Promise<WorkspaceSite> {
  const coordinates = num2LatLngLit(s.geometry.coordinates)
  const address = await revGeoCodeStreetPost(coordinates)

  console.debug('decoded geo information', { coordinates, address })

  s.properties.street = [address.street_number, address.route].join(' ')
  s.properties.post = address.postal_code || ''

  return s
}

interface SearchInfo {
  address: string
  status: string
  assessment_name: string
}

export interface ToolboxSite extends WorkspaceSite {
  selected: boolean
  /**
   * Used for fulltext site search
   */
  searchInfo: SearchInfo
  icon: string
}

export function convertToToolboxSites(sites: WorkspaceSite[] = []): ToolboxSite[] {
  return sites.map((site) => {
    const { description, street, post, status, assessment_name: assessmentName, aggregate_score, score, socket_count, charge_point_count, status_id, labels } = site.properties

    // DEM-3716: When a site is clicked for the first time, its description gets set
    // to its street. If they're the same, don't add both to the search string, as
    // this will change the siteSearchInfo object and trigger a rerender
    const streetAndDescription = street === description || !description
      ? street
      : street + ' ' + description

    return {
      ...site,
      selected: false,
      searchInfo: {
        address: (post + ' ' + streetAndDescription).toLocaleLowerCase(),
        status: status.toLocaleLowerCase(),
        status_id: status_id,
        assessment_name: assessmentName || '',
        aggregate_score,
        socket_count,
        charge_point_count,
        score: (score || []).map(s => s.score).join(','),
        labels: (labels || []).join(','),
      },
      icon: siteMarkerIcon(site).url,
    }
  })
}

export function workspaceOwnerName(workspace: Workspace | WorkspaceHistory, users: User[]): string {
  const ownerId = workspace.owner_id
  if (ownerId) {
    return users.find(user => user.id === ownerId)?.name || '/'
  }

  return workspace.owner || '/'
}
