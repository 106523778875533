import { RouteRecordRaw } from 'vue-router'
import devRoutes from './routes-dev'
import { AppRole } from '@/auth/roles'

export function getRoutes(): RouteRecordRaw[] {
  return [
    {
      path: '/public',
      name: 'public',
      component: () => import('./public/PublicApp.vue'),
      meta: { disableAuth: true },
      children: [
        {
          path: 'site-captures/:siteId',
          name: 'site-captures',
          props: true,
          component: () => import('./public/SiteImageCaptures.vue'),
          meta: { disableAuth: true },
        },
      ],
    },
    {
      name: 'root',
      path: '/',
      component: () => import('./App.vue'),
      children: [
        {
          path: '/test',
          name: 'test',
          component: () => import('./Test.vue'),
        },
        {
          path: '/region-error/:region',
          name: 'region-error',
          props: true,
          component: () => import('./MissingRegionPermission.vue'),
        },
        {
          name: 'app',
          path: '',
          redirect: 'workspace',
          component: () => import('./AppLayout.vue'),
          children: [
            {
              path: 'explorer',
              name: 'area-explorer',
              component: () => import('./AreaExplorer/AreaExplorer.vue'),
              meta: {
                roles: [AppRole.areaExplorer],
              },
            },
            {
              path: 'portfolio',
              name: 'portfolio',
              component: () => import('./Portfolio/Portfolio.vue'),
              children: [
                {
                  path: 'list',
                  name: 'portfolio/list',
                  component: () => import('./Workspace/SiteList/SiteList.vue'),
                  props: true,
                },
              ],
            },
            {
              path: 'workspace',
              component: () => import('./Workspace/WorkspaceLayout.vue'),
              children: [
                {
                  path: '',
                  name: 'workspace/list',
                  component: () => import('./Workspace/WorkspaceList.vue'),
                },
                {
                  path: 'create',
                  name: 'workspace/create',
                  component: () => import('./Workspace/CreateWorkspace/CreateWorkspace.vue'),
                },
                {
                  path: ':workspaceId',
                  name: 'workspace',
                  component: () => import('./Workspace/Workspace.vue'),
                  props: true,
                  children: [
                    {
                      path: 'edit',
                      name: 'workspace/editor',
                      component: () => import('./Workspace/EditWorkspace.vue'),
                    },
                    {
                      path: 'list',
                      name: 'workspace/site-list',
                      component: () => import('./Workspace/SiteList/SiteList.vue'),
                      props: true,
                    },
                  ],
                },
              ],
            },
            {
              path: 'admin',
              name: 'admin',
              component: () => import('./admin/AdminLayout.vue'),
              meta: {
                roles: [AppRole.organizationAdmin],
              },
              children: [
                {
                  path: 'config',
                  name: 'admin/config',
                  redirect: { name: 'admin/config/chargers' },
                  component: () => import('./admin/ConfigurationLayout.vue'),
                  children: [
                    {
                      path: 'data',
                      name: 'admin/config/data',
                      component: () => import('./Data.vue'),
                    },
                    {
                      path: 'chargers',
                      name: 'admin/config/chargers',
                      component: () => import('./admin/pages/ConfigurableChargerStationPage.vue'),
                    },
                    {
                      path: 'upload-dataset',
                      name: 'admin/config/upload-dataset',
                      component: () => import('./admin/pages/ConfigurableUploadDataset.vue'),
                    },
                    {
                      path: 'customize-fields',
                      name: 'admin/config/customize-fields',
                      component: () => import('./admin/pages/ConfigurableCustomField.vue'),
                    },
                    {
                      path: 'statuses',
                      name: 'admin/config/statuses',
                      component: () => import('./admin/pages/ConfigurableSiteStatusesPage.vue'),
                    },
                    {
                      path: 'workspaces',
                      name: 'admin/config/workspace-statuses',
                      component: () => import('./admin/pages/ConfigurableWorkspaceStatusesPage.vue'),
                    },
                    {
                      path: 'site-checklist',
                      name: 'admin/config/site-checklist',
                      component: () => import('./admin/pages/ConfigurableChecklistPage.vue'),
                    },
                    {
                      path: 'label-sets',
                      name: 'admin/config/label-sets',
                      meta: {
                        roles: [AppRole.labelSet],
                      },
                      component: () => import('./admin/pages/ConfigurableLabelSets.vue'),
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
    ...devRoutes,
    {
      path: '/:pathMatch(.*)*',
      redirect: '/',
    },
  ]
}
